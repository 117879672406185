.bgimg {
    background-image: url('../img/bg4.jpg');
    background-position: 0px 0px;
    background-size: 130%;
    height: 100vh;
}

.default {
    top: 0;
    position: absolute;
    left: 0;
    right: 0;
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
    text-align: center;
    justify-content: center;
    color: white;
}

.pagefill {
    top: 0;
    padding-bottom: 20px;
    max-width: 100vw;
    min-height: 50vh;
    display: flow;
    font-size: 20px;
    font-family: 'gravitylight';
    color: white;
    justify-items: center;
}

.arrow{
    position: absolute;
    bottom: 5%;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
}


@keyframes movebtn {
    0%{
      transform: translateY(0px);
    }
    50%{
      transform: translateY(-15px);
    }
    100%{
      transform: translateY(0px);
    }
  }

.justcolor {
    background-color: #1a1a1a;
    min-height: 100vh;
    text-align: center;
    justify-content: center;
}

.divcontainhome{
    padding-top: 200px;
    padding-bottom: 200px;
    text-align: left;
    padding-left: 15vw;
}

.divcontain{
    padding-top: 300px;
    padding-bottom: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.divcontainweb{
    padding-top: 150px;
    padding-bottom: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.divcontainsmall{
    padding-top: 100px;
    padding-bottom: 100px;
}

.breakpoint{
    position: relative;
    display: inline-block;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 1px;
    width: 80vw;
    background-color: #3b3b3b;
    max-width: 100vw;
}

.boxinfo{
    background-color: rgba(15, 15, 15, 0.15);
    -webkit-backdrop-filter: blur(15px);
    -moz-backdrop-filter: blur(15px);
    -o-backdrop-filter: blur(15px);
    -ms-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    border-radius: 5px;
    width: 35vw;
    height: 35vh;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: 'gravitylight';
    font-size: 25px;
    text-transform: uppercase;
}

.title{
    padding-bottom: 20px;
    font-family: 'gravityregular';
    font-size: 50px;
    text-transform: uppercase;
}

.subtitle{
    font-family: 'gravitylight';
    font-size: 35px;
    max-width: 800px;
    text-transform: uppercase;
    color: #f0f0f0;
    padding-bottom: 20px;
    padding-left: 4px;
}

.subtitle2{
    font-family: 'gravitylight';
    font-size: 24px;
    max-width: 500px;
    color: #f0f0f0;
    padding-bottom: 20px;
    padding-left: 4px;
    line-height: 1.4;
    word-spacing: 1px;
}

.title2{
    padding-bottom: 40px;
    font-family: 'gravitylight';
    font-size: 50px;
    text-transform: uppercase;
}

@keyframes movetitle2 {
    0%{
      transform: translateY(-150px);
    }
    100%{
      transform: translateY(0px);
    }
  }

.title3{
    padding-top: 100px;
    padding-bottom: 50px;
    font-family: 'gravitylight';
    font-size: 46px;
    text-transform: uppercase;
}

.fonteffect{
    background: linear-gradient(120deg,#ffc256,#525252);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contactinfo{
    padding-bottom: 120px;
    font-family: 'gravitylight';
    font-size: 20px;
    text-transform: uppercase;
}

.contactinfo li{
    padding: 5px;
    list-style-type: none;
    letter-spacing: 1px;
}

.bigbutton {
    background-color: #ffc256;
    border: none;
    color: rgb(51, 51, 51);
    padding: 12px;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 8px 8px;
    cursor: pointer;
    border-radius: 5px;
    transition-duration: .4s;
    text-transform: uppercase;
}

.bigbutton:hover{
    background-color: #ffbb46;
}

.link {
    color: #29515c;
}

.gridbenefits {
    display: grid;
    grid-template-columns: repeat(3, 0.25fr);
    gap: 4%;
    padding: 200px 0;
    justify-content: center;
}

.benefits li {
    padding-bottom: 30px;
    list-style-type: none;
    letter-spacing: 1px;
}

.benefits{
    margin: 0px 0;
    height: 38%;
}

.gridfeatures {
    display: grid;
    grid-template-columns: repeat(2, 0.22fr);
    gap: 3%;
    padding: 2% 0;
    justify-content: center;
}

.features li {
    padding-bottom: 0px;
    list-style-type: none;
    letter-spacing: 1px;
}

.features{
    margin: 0px 0;
    height: 38%;
}

.benefitimg{
    width: 50px;
    height: 50px;
    position: relative;
    display: flex;
    overflow: hidden;
    background-size: 100%;
    align-items: center;
    filter: invert(1);
}

.roundbox{
    border-radius: 10px;
    padding: 20px 20px 20px 20px;
    background-color: #e8e8e812;
    color: #ffffff;
    width: 360px;
    height: 200px;
}

.centerfeature{
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
}

.centerfeatureTitle{
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
    font-family: 'gravityregular';
    font-size: 24px;
}


@media only screen and (max-width: 900px) {
    .default {
        background-color: #535353;
        background-image: url('../img/bg4.jpg');
        background-position: -90px 0px;
        background-size: 400%;
        min-height: 100vh;
        max-height: 100vh;
        height: 100vh;
        text-align: center;
        justify-content: center;
        color: white;
    }
    
    .pagefill {
        padding-bottom: 20px;
        max-width: 100vw;
        min-height: 50vh;
        display: flow;
        font-size: 12px;
        font-family: 'gravityregular';
        color: white;
        justify-items: center;
    }

    .justcolor {
        background-color: #1a1a1a;
        min-height: 100vh;
        text-align: center;
        justify-content: center;
    }
    
    .divcontainhome{
        padding-top: 120px;
        padding-bottom: 200px;
        text-align: left;
        padding-left: 30px;
    }
    
    .divcontain{
        padding-top: 240px;
        padding-bottom: 200px;
    }
    
    .divcontainsmall{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .breakpoint{
        position: relative;
        display: inline-block;
        justify-content: center;
        align-content: center;
        align-items: center;
        height: 1px;
        width: 80vw;
        background-color: #3b3b3b;
        max-width: 100vw;
    }
    
    .boxinfo{
        background-color: rgba(15, 15, 15, 0.15);
        -webkit-backdrop-filter: blur(15px);
        -moz-backdrop-filter: blur(15px);
        -o-backdrop-filter: blur(15px);
        -ms-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        border-radius: 5px;
        width: 35vw;
        height: 35vh;
        padding-top: 15px;
        padding-bottom: 15px;
        font-family: 'gravitylight';
        font-size: 25px;
        text-transform: uppercase;
    }
    
    .title{
        padding-bottom: 20px;
        font-family: 'gravitybold';
        font-size: 28px;
        text-transform: uppercase;
    }
    
    .subtitle{
        font-family: 'gravityregular';
        font-size: 20px;
        text-transform: uppercase;
        color: #ffffff;
        padding-bottom: 20px;
        padding-left: 4px;
    }
    
    .subtitle2{
        font-family: 'gravitylight';
        font-size: 20px;
        color: #ffffff;
        padding-bottom: 15px;
        padding-left: 25px;
        padding-right: 25px;
        line-height: 1.5;
        word-spacing: 2px;
    }
    
    .title2{
        padding-bottom: 20px;
        font-family: 'gravityregular';
        font-size: 36px;
        text-transform: uppercase;
    }

    .fonteffect{
        background: linear-gradient(120deg,#ffc256,#525252);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    .contactinfo{
        padding-bottom: 120px;
        font-family: 'gravitylight';
        font-size: 20px;
        text-transform: uppercase;
    }
    
    .contactinfo li{
        padding: 5px;
        list-style-type: none;
        letter-spacing: 1px;
    }
    
    .bigbutton {
        background-color: #ffc256;
        border: none;
        color: rgb(51, 51, 51);
        padding: 12px;
        padding-left: 40px;
        padding-right: 40px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 20px;
        margin: 8px 8px;
        cursor: pointer;
        border-radius: 5px;
        transition-duration: .4s;
        text-transform: uppercase;
    }
    
    .bigbutton:hover{
        background-color: #ffbb46;
    }
    
    .link {
        color: #29515c;
    }
    
    .gridbenefits {
        display: grid;
        grid-template-columns: repeat(3, 0.25fr);
        gap: 4%;
        padding: 100px 0;
        justify-content: center;
    }
    
    .benefits li {
        padding-bottom: 30px;
        list-style-type: none;
        letter-spacing: 1px;
    }
    
    .benefits{
        margin: 0px 0;
        height: 38%;
    }
    
    .gridfeatures {
        display: grid;
        grid-template-columns: repeat(1, 0.25fr);
        gap: 30px;
        padding: 15px 0;
        justify-content: center;
    }
    
    .features li {
        padding-bottom: 0px;
        list-style-type: none;
        letter-spacing: 1px;
    }
    
    .features{
        margin: 0px 0;
        height: 38%;
    }
    
    .benefitimg{
        width: 50px;
        height: 50px;
        position: relative;
        display: flex;
        overflow: hidden;
        background-size: 100%;
        align-items: center;
        filter: invert(1);
    }

    .roundbox{
        border-radius: 10px;
        padding: 20px 20px 20px 20px;
        background-color: #e8e8e812;
        color: #ffffff;
        width: 250px;
        height: 180px;
    }
    
    .centerfeature{
        position: relative;
        display: flex;
        width: 230px;
        align-items: center;
        justify-content: center;
        padding-bottom: 15px;
    }

    .centerfeatureTitle{
        position: relative;
        display: flex;
        width: 230px;
        align-items: center;
        justify-content: center;
        padding-bottom: 15px;
        font-family: 'gravityregular';
        font-size: 22px;
    }
    
  }