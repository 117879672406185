.default {
    background-color: #535353;
    background-image: url('../img/bg1.jpg');
    background-position: 0px -350px;
    background-size: 100%;
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
    text-align: center;
    justify-content: center;
}

.pagefill {
    padding-top: 20px;
    padding-bottom: 20px;
    max-width: 100vw;
    min-height: 100vh;
    display: grid;
    grid-gap: 150px;
    align-items: center;
    font-size: 20px;
    font-family: 'gravitylight';
    /* gap: 5vw; */
    color: white;
    align-content: center;
    justify-content: center;
    justify-items: center;
}

.arrow{
    position: absolute;
    bottom: 5%;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
}


@keyframes movebtn {
    0%{
      transform: translateY(0px);
    }
    50%{
      transform: translateY(-15px);
    }
    100%{
      transform: translateY(0px);
    }
  }

.justcolor {
    background-color: #1a1a1a;
    min-height: 100vh;
    text-align: center;
    justify-content: center;
}

.divcontain{
    padding-top: 200px;
    padding-bottom: 50px;
}

.divcontainsmall{
    padding-top: 0px;
    padding-bottom: 50px;
}

.breakpoint{
    position: relative;
    display: inline-block;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 1px;
    width: 80vw;
    background-color: #3b3b3b;
    max-width: 100vw;
}

.boxinfo{
    background-color: rgba(15, 15, 15, 0.15);
    -webkit-backdrop-filter: blur(15px);
    -moz-backdrop-filter: blur(15px);
    -o-backdrop-filter: blur(15px);
    -ms-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    border-radius: 5px;
    width: 35vw;
    height: 35vh;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: 'gravitylight';
    font-size: 25px;
    text-transform: uppercase;
}

.title{
    padding-bottom: 250px;
    font-family: 'gravitylight';
    font-size: 40px;
    text-transform: uppercase;
}

.title2{
    padding-bottom: 80px;
    font-family: 'gravitylight';
    font-size: 40px;
    text-transform: uppercase;
}

.subtitle{
    font-family: 'gravityregular';
    font-size: 20px;
    color: #f0f0f0;
    padding-bottom: 80px;
    padding-left: 4px;
    line-height: 1.5;
    word-spacing: 2px;
}

.contactinfo{
    padding-bottom: 120px;
    font-family: 'gravitylight';
    font-size: 20px;
    text-transform: uppercase;
}

.contactinfo li{
    padding: 5px;
    list-style-type: none;
    letter-spacing: 1px;
}

.bigbutton {
    background-color: #ffc256;
    border: none;
    color: rgb(51, 51, 51);
    padding: 12px;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 8px 8px;
    cursor: pointer;
    border-radius: 5px;
    transition-duration: .4s;
    text-transform: uppercase;
}

.bigbutton:hover{
    background-color: #ffbb46;
}

.link {
    color: #29515c;
}

.calendar{
    height:900px;
    padding-top:100px;
}


@media only screen and (max-width: 900px) {
    .default {
        background-color: #535353;
        background-image: url('../img/bg1.jpg');
        background-position: 0px -200px;
        background-size: 300%;
        min-height: 100vh;
        max-height: 100vh;
        height: 100vh;
        text-align: center;
        justify-content: center;
    }
    
    .pagefill {
        padding-top: 20px;
        padding-bottom: 20px;
        max-width: 100vw;
        min-height: 100vh;
        display: grid;
        grid-gap: 25px;
        align-items: center;
        font-size: 20px;
        font-family: 'gravitylight';
        /* gap: 5vw; */
        color: white;
        align-content: center;
        justify-content: center;
        justify-items: center;
    }
    
    .justcolor {
        background-color: #1a1a1a;
        min-height: 100vh;
        text-align: center;
        justify-content: center;
    }
    
    .divcontain{
        padding-top: 100px;
        padding-bottom: 0px;
    }
    
    .divcontainsmall{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .breakpoint{
        position: relative;
        display: inline-block;
        justify-content: center;
        align-content: center;
        align-items: center;
        height: 1px;
        width: 80vw;
        background-color: #3b3b3b;
        max-width: 100vw;
    }
    
    .boxinfo{
        background-color: rgba(15, 15, 15, 0.15);
        -webkit-backdrop-filter: blur(15px);
        -moz-backdrop-filter: blur(15px);
        -o-backdrop-filter: blur(15px);
        -ms-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        border-radius: 5px;
        width: 35vw;
        height: 35vh;
        padding-top: 15px;
        padding-bottom: 15px;
        font-family: 'gravitylight';
        font-size: 25px;
        text-transform: uppercase;
    }
    
    .title{
        padding-bottom: 250px;
        font-family: 'gravitylight';
        font-size: 40px;
        text-transform: uppercase;
    }
    
    .title2{
        padding-top: 20px;
        padding-bottom: 25px;
        font-family: 'gravitylight';
        font-size: 28px;
        text-transform: uppercase;
    }

    .subtitle{
        font-family: 'gravityregular';
        font-size: 14px;
        color: #f0f0f0;
        padding-bottom: 20px;
        padding-left: 25px;
        padding-right: 25px;
        line-height: 1.5;
        word-spacing: 2px;
    }
    
    .contactinfo{
        padding-bottom: 0px;
        font-family: 'gravityregular';
        font-size: 14px;
        text-transform: uppercase;
    }
    
    .contactinfo li{
        padding: 5px;
        list-style-type: none;
        letter-spacing: 1px;
    }
}