.footer {
    position: relative;
    background-color: #131313;
    min-height: 10vh;
    height: 5%;
    max-width: 100vw;
    color: white;
    bottom: 0;
    font-family: 'gravitylight';
    font-size: 15px;
    padding-bottom: 30px;
    padding-top: 50px;
    padding-left: 200px;
    padding-right: 200px;
}

.logo{
    margin-bottom: 50px;
    text-align: center;
    filter: contrast(110%);
}

.smedialogos{
    margin-top: 50px;
    filter: contrast(60%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
}

.breakpoint{
    justify-content: center;
    height: 1px;
    width: 100%;
    background-color: #3b3b3b;
}


.links {
    justify-items: center;
    padding-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    text-align: center;
}

.link{
    display: block;
    text-align: left;
    font-weight: 400;
    letter-spacing: 3px;
    font-size: 18px;
    line-height: 30px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.temp{
    
    
    display: grid;
    align-items: center;
    justify-content: center;

    display: block;
    text-align: left;
    font-weight: 400;
    letter-spacing: .18182em;
    font-size: 1.1rem;
    line-height: 1.63636;
    text-transform: uppercase;
    margin-bottom: 16px;
    color: #fff
    
}

.langdrop {
    opacity: 0;
    display: none;
    position: absolute;
    transition: .4s ease-out;
    z-index: 2;
}

.langdrop.active {
    opacity: 1;
    display: block;
    position: absolute;
    transition: .4s ease-out;
    font-family: 'gravitylight';
    z-index: 2;
}

.pointer {
    cursor: pointer;
}

.product{
    font-family: 'gravitylight';
    font-size: 12px;
    text-transform: uppercase;
    color: #535353;
    text-align: center;
    padding-top: 25px;
}



@media only screen and (max-width: 900px) {

    .footer {
        position: relative;
        background-color: #131313;
        min-height: 10vh;
        height: 5%;
        max-width: 100vw;
        color: white;
        bottom: 0;
        font-family: 'gravitylight';
        font-size: 15px;
        padding-bottom: 50px;
        padding-top: 50px;
        padding-left: 0px;
        padding-right: 0px;
    }
    
    .logo{
        margin-bottom: 50px;
        text-align: center;
        filter: contrast(110%);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .smedialogos{
        margin-top: 50px;
        filter: contrast(60%);
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 40px;
    }
    
    .breakpoint{
        height: 1px;
        width: 80vw;
        position: absolute;
        background-color: #3b3b3b;
        left: 10vw;
    }
    
    
    .links {
        justify-items: center;
        padding-top: 24px;
        display: grid;
        grid-template-columns: repeat(2, 38vw);
        text-align: center;
        justify-content: center;
        justify-items: start;
        padding-left: 30px;
    }
    
    .link{
        display: block;
        text-align: left;
        font-weight: 400;
        letter-spacing: 3px;
        font-size: 10px;
        line-height: 30px;
        text-transform: uppercase;
        font-family: 'gravityregular';
        margin-bottom: 20px;
    }
    
    .temp{
        
        
        display: grid;
        align-items: center;
        justify-content: center;
    
        display: block;
        text-align: left;
        font-weight: 400;
        letter-spacing: .18182em;
        font-size: 1.1rem;
        line-height: 1.63636;
        text-transform: uppercase;
        margin-bottom: 16px;
        color: #fff
        
    }
    
    .langdrop {
        opacity: 0;
        display: none;
        position: absolute;
        transition: .4s ease-out;
        z-index: 2;
    }
    
    .langdrop.active {
        opacity: 1;
        display: block;
        position: absolute;
        transition: .4s ease-out;
        font-family: 'gravitylight';
        z-index: 2;
    }

    .product{
        font-family: 'gravitylight';
        font-size: 10px;
        text-transform: uppercase;
        color: #535353;
        text-align: center;
        padding-top: 25px;
    }

}