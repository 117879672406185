/*@font-face {
    font-family: 'Gravity-Bold', sans-serif;
    src: url('./Gravity-Bold.otf') format("opentype"),
    url('./Gravity-Bold.woff') format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gravity-BoldItalic', sans-serif;
    src: url('./Gravity-BoldItalic.otf') format("opentype"),
    url('./Gravity-BoldItalic.woff') format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gravity-Book', sans-serif;
    font-weight: normal;
    font-style: normal;
    src: url('./Gravity-Book.otf') format("opentype"),
    url('./Gravity-Book.woff') format("woff");
}
@font-face {
    font-family: 'Gravity-BookItalic', sans-serif;
    font-weight: normal;
    font-style: normal;
    src: url('./Gravity-BookItalic.otf') format("opentype"),
    url('./Gravity-BookItalic.woff') format("woff");
}
@font-face {
    font-family: 'Gravity-Italic', sans-serif;
    src: url('./Gravity-Italic.otf') format("opentype"),
    url('./Gravity-Italic.woff') format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gravity-Light', sans-serif;
    src: url('./Gravity-Light.otf') format("opentype"),
    url('./Gravity-Light.woff') format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gravity-LightItalic', sans-serif;
    src: url('./Gravity-LightItalic.otf') format("opentype"),
    url('./Gravity-LightItalic.woff') format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gravity-Regular', sans-serif;
    src: url('./Gravity-Regular.otf') format("opentype"),
    url('./Gravity-Regular.woff') format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gravity-UltraLight', sans-serif;
    src: url('./Gravity-UltraLight.otf') format("opentype"),
    url('./Gravity-UltraLight.woff') format("woff");
    font-weight: normal;
    font-style: normal;
    
}
@font-face {
    font-family: 'Gravity-UltraLightItalic', sans-serif;
    src: url('./Gravity-UltraLightItalic.otf') format("opentype"),
    url('./Gravity-UltraLightItalic.woff') format("woff");
    font-weight: normal;
    font-style: normal;
}*/
@font-face {
    font-family: 'gravitybold';
    src: url('./gravity-bold-webfont.woff2') format('woff2'),
         url('./gravity-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gravitybold_italic';
    src: url('./gravity-bolditalic-webfont.woff2') format('woff2'),
         url('./gravity-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gravitybook';
    src: url('./gravity-book-webfont.woff2') format('woff2'),
         url('./gravity-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gravitybook_italic';
    src: url('./gravity-bookitalic-webfont.woff2') format('woff2'),
         url('./gravity-bookitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gravityitalic';
    src: url('./gravity-italic-webfont.woff2') format('woff2'),
         url('./gravity-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gravitylight';
    src: url('./Gravity-Light.otf') format("opentype");
    src: local('☺︎'),
        url('./gravity-light-webfont.woff2') format('woff2'),
        url('./gravity-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gravitylight_italic';
    src: url('./gravity-lightitalic-webfont.woff2') format('woff2'),
         url('./gravity-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gravityregular';
    src: url('./gravity-regular-webfont.woff2') format('woff2'),
         url('./gravity-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gravityultralight';
    src: url('./gravity-ultralight-webfont.woff2') format('woff2'),
         url('./gravity-ultralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gravityultralight_italic';
    src: url('./gravity-ultralightitalic-webfont.woff2') format('woff2'),
         url('./gravity-ultralightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}