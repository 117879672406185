.default {
    background-color: #535353;
    background-image: url('../img/bg3.jpg');
    background-position: 0px -150px;
    background-size: 100%;
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
    text-align: center;
    justify-content: center;
}

.pagefill {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    max-width: 100vw;
    min-height: 100vh;
    display: grid;
    grid-gap: 150px;
    align-items: center;
    font-size: 20px;
    font-family: 'gravitylight';
    gap: 50px;
    color: white;
    align-content: center;
    justify-content: center;
    justify-items: center;
}

.justcolor {
    background-color: #1a1a1a;
    min-height: 100vh;
    text-align: center;
    justify-content: center;
}

.divcontain{
    padding-top: 100px;
    padding-bottom: 0px;
    height: 100%;
}

.divcontainsmall{
    padding-top: 0px;
    padding-bottom: 150px;
}

.breakpoint{
    position: relative;
    display: inline-block;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 1px;
    width: 80vw;
    background-color: #3b3b3b;
    max-width: 100vw;
}

.boxinfo{
    background-color: rgba(15, 15, 15, 0.15);
    -webkit-backdrop-filter: blur(15px);
    -moz-backdrop-filter: blur(15px);
    -o-backdrop-filter: blur(15px);
    -ms-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    border-radius: 5px;
    width: 35vw;
    height: 35vh;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: 'gravitylight';
    font-size: 25px;
    text-transform: uppercase;
}

.title{
    padding-bottom: 250px;
    font-family: 'gravitylight';
    font-size: 40px;
    text-transform: uppercase;
}

.title2{
    padding-top: 200px;
    padding-bottom: 80px;
    font-family: 'gravitylight';
    font-size: 46px;
    text-transform: uppercase;
}

.subtitle{
    font-family: 'gravitylight';
    font-size: 24px;
    color: #f0f0f0;
    padding-bottom: 2px;
    padding-left: 4px;
    line-height: 1.4;
    word-spacing: 1px;
}

.fonteffect{
    background: linear-gradient(180deg,#ffc256,#525252);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.fonteffect2{
    font-style: italic;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ffffff;
    color: transparent;
}

.contactinfo{
    padding-bottom: 30px;
    font-family: 'gravitylight';
    font-size: 20px;
    text-transform: uppercase;
}

.contactinfo li{
    padding: 5px;
    list-style-type: none;
    letter-spacing: 1px;
}

.bigbutton {
    background-color: #fafafa;
    border: none;
    color: rgb(51, 51, 51);
    padding: 12px;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 40px;
    transition-duration: .4s;
    text-transform: uppercase;
}

.bigbutton:hover{
    background-color: #cfcfcf;
}

.link {
    color: #29515c;
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4%;
    padding: 5% 0;
    justify-content: center;
}

.cardgold {
    padding: 50px;
    background: rgba(200, 200, 200, 0.1);
    backdrop-filter: blur(10px);
    outline: solid;
    outline-width: 3px;
    outline-color: #ffc256;
    border-radius: 8px;
    font-family: 'gravitylight';
    text-transform: uppercase;
}

.card {
    padding: 50px;
    background: rgba(200, 200, 200, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    font-family: 'gravitylight';
    text-transform: uppercase;
}

.card_title {
    font-weight: normal;
    font-size: 30px;
    margin-bottom: 5px;
}

p {
    display: flex;
    margin-block-start: 10px;
    margin-block-end: 10px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    width: 15vw;
    justify-content: center;
}

.pricing {
    font-family: 'gravitylight';
    font-weight: normal;
    font-size: 65px;
}

hr {
    margin-top: 5px;
}

.features li {
    padding-bottom: 10px;
    list-style-type: none;
    letter-spacing: 1px;
}

.features{
    margin: 40px 0;
    height: 38%;
}

a.cta_btn {
    width: 100%;
    display: inline-block;
    text-align: center;
    font-family: "gravitylight";
    font-size: 20px;
    background: #ffc256;
    border-radius: 5px;
    padding: 20px 0;
    color: rgb(0, 0, 0);
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: background .3s ease;
}

.bigbutton {
    background-color: #ffc256;
    border: none;
    color: rgb(51, 51, 51);
    padding: 12px;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 8px 8px;
    cursor: pointer;
    border-radius: 5px;
    transition-duration: .4s;
    text-transform: uppercase;
}

.bigbutton:hover{
    background-color: #ffbb46;
}

a.cta_btn:hover {
    background: #ffbb46;
}

.arrow{
    position: absolute;
    bottom: 5%;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
}


@keyframes movebtn {
    0%{
      transform: translateY(0px);
    }
    50%{
      transform: translateY(-15px);
    }
    100%{
      transform: translateY(0px);
    }
  }


@media only screen and (max-width: 900px) {

    .default {
        min-height: 100vh;
        max-height: 100vh;
        height: 100vh;
        text-align: center;
        justify-content: center;
    }
    
    .default::before{
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #535353;
        background-image: url('../img/bg3.jpg');
        background-position: -700px -200px;
        background-size: 450%;
        filter: contrast(130%);
    }
    
    .pagefill {
        position: relative;
        padding-top: 20px;
        padding-bottom: 20px;
        max-width: 100vw;
        min-height: 100vh;
        display: grid;
        grid-gap: 150px;
        align-items: center;
        font-size: 20px;
        font-family: 'gravitylight';
        gap: 50px;
        color: white;
        align-content: center;
        justify-content: center;
        justify-items: center;
    }
    
    .justcolor {
        background-color: #1a1a1a;
        min-height: 100vh;
        text-align: center;
        justify-content: center;
    }
    
    .divcontain{
        padding-top: 100px;
        padding-bottom: 50px;
        height: 100%;
    }
    
    .divcontainsmall{
        padding-top: 0px;
        padding-bottom: 50px;
    }
    
    .breakpoint{
        position: relative;
        display: inline-block;
        justify-content: center;
        align-content: center;
        align-items: center;
        height: 1px;
        width: 80vw;
        background-color: #3b3b3b;
        max-width: 100vw;
    }
    
    .boxinfo{
        background-color: rgba(15, 15, 15, 0.15);
        -webkit-backdrop-filter: blur(15px);
        -moz-backdrop-filter: blur(15px);
        -o-backdrop-filter: blur(15px);
        -ms-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        border-radius: 5px;
        width: 35vw;
        height: 35vh;
        padding-top: 15px;
        padding-bottom: 15px;
        font-family: 'gravitylight';
        font-size: 25px;
        text-transform: uppercase;
    }
    
    .title{
        padding-bottom: 250px;
        font-family: 'gravitylight';
        font-size: 40px;
        text-transform: uppercase;
    }
    
    .title2{
        padding-top: 160px;
        padding-bottom: 80px;
        font-family: 'gravitylight';
        font-size: 40px;
        text-transform: uppercase;
    }

    .subtitle{
        font-family: 'gravitylight';
        font-size: 24px;
        color: #f0f0f0;
        padding-bottom: 2px;
        padding-left: 25px;
        padding-right: 25px;
        line-height: 1.4;
        word-spacing: 1px;
    }
    
    .contactinfo{
        padding-bottom: 30px;
        font-family: 'gravitylight';
        font-size: 20px;
        text-transform: uppercase;
    }
    
    .contactinfo li{
        padding: 5px;
        list-style-type: none;
        letter-spacing: 1px;
    }
    
    .bigbutton {
        background-color: #fafafa;
        border: none;
        color: rgb(51, 51, 51);
        padding: 12px;
        padding-left: 40px;
        padding-right: 40px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 20px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 40px;
        transition-duration: .4s;
        text-transform: uppercase;
    }
    
    .bigbutton:hover{
        background-color: #cfcfcf;
    }
    
    .link {
        color: #29515c;
    }
    
    .grid {
        display: grid;
        grid-template-columns: repeat(1, 70vw);
        gap: 4%;
        padding: 5% 0;
        justify-content: center;
    }
    
    .cardgold {
        padding: 50px;
        background: rgba(200, 200, 200, 0.1);
        backdrop-filter: blur(10px);
        outline: solid;
        outline-width: 3px;
        outline-color: #ffc256;
        border-radius: 8px;
        font-family: 'gravitylight';
        text-transform: uppercase;
    }
    
    .card {
        padding: 50px;
        background: rgba(200, 200, 200, 0.1);
        backdrop-filter: blur(10px);
        border-radius: 8px;
        font-family: 'gravitylight';
        text-transform: uppercase;
    }
    
    .card_title {
        font-weight: normal;
        font-size: 30px;
        margin-bottom: 5px;
    }
    
    p {
        display: flex;
        margin-block-start: 0px;
        margin-block-end: 5px;
        margin-inline-start: 10px;
        margin-inline-end: 0px;
        width: 15vw;
        justify-content: space-between;
    }
    
    .pricing {
        font-family: 'gravitylight';
        font-weight: normal;
        font-size: 65px;
    }
    
    hr {
        margin-top: 5px;
    }
    
    .features li {
        padding-bottom: 10px;
        list-style-type: none;
        letter-spacing: 1px;
    }
    
    .features{
        margin: 40px 0;
        height: 160px;
    }
    
    a.cta_btn {
        width: 100%;
        display: inline-block;
        text-align: center;
        font-family: "gravitylight";
        font-size: 20px;
        background: #ffc256;
        border-radius: 5px;
        padding: 20px 0;
        color: rgb(0, 0, 0);
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 2px;
        transition: background .3s ease;
    }

}