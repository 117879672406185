.primeheader{
    position: relative;
    height: 95px;
    top: 0;
    opacity: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 15px
}

.breakpoint{
    position: relative;
    display: inline-block;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 1px;
    width: 80vw;
    background-color: #3b3b3b;
    max-width: 100vw;
}

.navmenuimg{
    filter: contrast(60%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.langimg{
  filter: contrast(80%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.closemenuimg{
    filter: contrast(60%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding-right: 10px;
}

.ntslogo{
    transform: scale(0.55);
    position: relative;
    display: flex;
    overflow: hidden;
    background-size: 100%;
    align-items: center;
}

.navbar {
    background-color: #1a1a1a;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  
  .navmenu {
    background-color: rgba(15, 15, 15, 0.15);
    width: 30vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 1s ease-in-out;
    z-index: 2;
    -webkit-backdrop-filter: blur(15px);
    -moz-backdrop-filter: blur(15px);
    -o-backdrop-filter: blur(15px);
    -ms-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
  }
  
  .navmenu.active {
    left: 0;
    transition: .4s ease-out;
  }
  
  .navtext {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
    text-decoration: none;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
  
  .navtext a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 20px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    text-decoration: none;
  }
  
  .navtext a:hover {
    background-color: rgba(15, 15, 15, 0.05);
    text-decoration: none;
  }
  
  .navmenuitems {
    width: 100%;
    color: white;
    font-family: 'gravitylight';
    font-size: 30px;
    list-style-type: none;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 15px;
  }
  
  .navbartoggle {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: right;
    padding-top: 20px;
  }

  .langdrop {
    opacity: 0;
    display: none;
    position: absolute;
    transition: .4s ease-out;
    z-index: 2;
  }

  .langdrop.active {
    opacity: 1;
    display: block;
    position: absolute;
    transition: .4s ease-out;
    font-family: 'gravitylight';
    z-index: 2;
  }


@media only screen and (max-width: 900px) {

  .primeheader{
    position: relative;
    height: 95px;
    top: 0;
    opacity: 0;
    width: 90%;
    display: flex;
    padding-left: 20px;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
}

.breakpoint{
    position: relative;
    display: inline-block;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 1px;
    width: 80vw;
    background-color: #3b3b3b;
    max-width: 100vw;
}

.navmenuimg{
    filter: contrast(60%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.langimg{
  filter: contrast(80%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.closemenuimg{
    filter: contrast(60%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding-right: 10px;
}

.ntslogo{
    transform: scale(0.8);
    position: relative;
    width: 200px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    overflow: hidden;
    background-size: 100%;
    align-items: center;
    justify-content: center;
}

.navbar {
    background-color: #1a1a1a;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  
  .navmenu {
    background-color: rgba(15, 15, 15, 0.15);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 1s ease-in-out;
    z-index: 2;
    -webkit-backdrop-filter: blur(15px);
    -moz-backdrop-filter: blur(15px);
    -o-backdrop-filter: blur(15px);
    -ms-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
  }
  
  .navmenu.active {
    left: 0;
    transition: .4s ease-out;
  }
  
  .navtext {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
    text-decoration: none;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
  
  .navtext a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 20px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    text-decoration: none;
  }
  
  .navtext a:hover {
    background-color: rgba(15, 15, 15, 0.05);
    text-decoration: none;
  }
  
  .navmenuitems {
    width: 100%;
    color: white;
    font-family: 'gravitylight';
    font-size: 30px;
    list-style-type: none;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 0px;
  }
  
  .navbartoggle {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: right;
    padding-top: 20px;
  }

  .langdrop {
    opacity: 0;
    display: none;
    position: absolute;
    transition: .4s ease-out;
    z-index: 2;
  }

  .langdrop.active {
    opacity: 1;
    display: block;
    position: absolute;
    transition: .4s ease-out;
    font-family: 'gravitylight';
    z-index: 2;
  }

}