.App {
  background-color: #242424;
  min-height: 100vh;
  text-align: center;
}

.App-logo {
  height: 80px;
  pointer-events: none;
}

.App-ContentFill {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background-color: #535353;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  gap: 15vw;
  color: white;
}

.App-link {
  color: #29515c;
}